import React, { useState, useEffect } from "react";
import "./Countdown.css";
import Snowflakes from "./components/Snowflake";

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const [prevTimeLeft, setPrevTimeLeft] = useState({});
  const [animationClasses, setAnimationClasses] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const nextChristmas = new Date(now.getFullYear(), 11, 25); // December 25 of the current year

      if (now > nextChristmas) {
        nextChristmas.setFullYear(nextChristmas.getFullYear() + 1);
      }

      const difference = nextChristmas - now;

      const newTimeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };

      setPrevTimeLeft(timeLeft);
      setTimeLeft(newTimeLeft);

      const newAnimationClasses = {};

      // Apply animation class only if the value has changed
      Object.keys(newTimeLeft).forEach((key) => {
        newAnimationClasses[key] = newTimeLeft[key] !== prevTimeLeft[key] ? "pulse" : "";
      });

      setAnimationClasses(newAnimationClasses);

      // Remove the animation class after the animation completes
      setTimeout(() => {
        setAnimationClasses({
          days: "",
          hours: "",
          minutes: "",
          seconds: "",
        });
      }, 500);
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, prevTimeLeft]);

  return (
    <div className="main">
      <Snowflakes />

      <h1 className="countdown-text">Christmas Countdown</h1>

      <div className="countdown-timer">
        <div className="countdown-item">
          <span className={`countdown-value ${animationClasses.days}`}>{timeLeft.days}</span>
          <span className="countdown-label">Days</span>
        </div>
        <div className="countdown-item">
          <span className={`countdown-value ${animationClasses.hours}`}>{timeLeft.hours}</span>
          <span className="countdown-label">Hours</span>
        </div>
        <div className="countdown-item">
          <span className={`countdown-value ${animationClasses.minutes}`}>{timeLeft.minutes}</span>
          <span className="countdown-label">Minutes</span>
        </div>
        <div className="countdown-item">
          <span className={`countdown-value ${animationClasses.seconds}`}>{timeLeft.seconds}</span>
          <span className="countdown-label">Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
